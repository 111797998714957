import page from 'page'
import { ThatGui } from 'that-gui'
import 'that-gui/src/components/that-menu'

window.addEventListener('load', () => {
  let sketchInstances = []
  const gui = new ThatGui({ 
    width: 500, 
    theme: { 
      'primary': '250deg, 25%, 50%',
      'surface': '240deg, 10%, 10%',
      'onSurface': '0deg, 0%, 100%',
    }
  })
  gui.container.classList.add("gui")

  const sketches = {
    'central-vibrance': import('./sketches/central-vibrance.js'),
    'pivot': import('./sketches/pivot.js'),
    '2': import('./sketches/2.js'),
    // '3': import('./sketches/3.js'),
    'noise-density CPU': import('./sketches/density.js'),
    'noise-density GPU': import('./sketches/density-glsl.js'),
    // 'that-gui-demo': import('./sketches/that-gui-demo.js'),
  }
  
  const sketchMenuElem = document.createElement('that-menu')
  sketchMenuElem.label = 'Sketch'
  sketchMenuElem.options = Object.keys(sketches)
  sketchMenuElem.addEventListener('change', (e) => {
    page('/' + e.target.value)
  })
  sketchMenuElem.classList.add('sketch-menu')

  const containerElem = document.querySelector('.container')
  gui.container.addEventListener('minimisetoggled', (event) =>
    event.target.minimised
      ? containerElem.classList.remove('container--gui-open')
      : containerElem.classList.add('container--gui-open'),
  )
  containerElem.append(sketchMenuElem)

  page('/', Object.keys(sketches)[0])
  page('/:sketch', (ctx) => {
    sketchMenuElem.value = ctx.params.sketch

    sketches[ctx.params.sketch].then((module) => {
      gui.clear()
      for (const sketch of sketchInstances) sketch.remove()
      sketchInstances = module.setup(gui)
      for (const sketch of sketchInstances) document.getElementsByClassName('container')[0].append(sketch.canvas)
    })
  })
  page()
})
